/* Copyright 2024 City Trax Limited. All rights reserved. */

import { Authenticator } from '@aws-amplify/ui-react';
import '@aws-amplify/ui-react/styles.css';
import { Hub } from 'aws-amplify/utils';
import { useEffect, useState } from 'react';
import { fetchUserAttributes } from 'aws-amplify/auth';
import {
  TopNavigation,
  AppLayout,
  SideNavigation,
} from '@cloudscape-design/components';
import {
  useNavigate,
  useLocation,
  Outlet
} from "react-router-dom";



const App = ({ children }) => {
  const [email, setEmail] = useState('')
  const [activeHref, setActiveHref] = useState(useLocation().pathname);
  const [navigationOpen, setNavigationOpen] = useState(true)
  const navigate = useNavigate();
  let location = useLocation();

  const listener = (data) => {
    if (data.payload.event === 'signedIn') {
      setEmail(data.payload.data.signInDetails.loginId);
    } else if (data.payload.event === 'signedOut') {
      setEmail('');
    }
  };
  useEffect(() => {
    handleFetchUserAttributes();
  }, []);

  useEffect(() => {
    setActiveHref(location.pathname)
  }, [location]);

  const handleFetchUserAttributes = async () => {
    try {
      const userAttributes = await fetchUserAttributes();
      setEmail(userAttributes.email);
      console.log(userAttributes.email)
    } catch (error) {
    }
  }

  const toggleNavigation = () => {
    setNavigationOpen(!navigationOpen)
  }

  Hub.listen('auth', listener);

  let cityTraxItems = [];
  if (window.config.audioTranscribeApiEndpoint !== undefined) {
    cityTraxItems.push({ type: 'link', text: `Transcriptions`, href: `/audio-transcribe/` })
    cityTraxItems.push({ type: 'link', text: `Create New Transcription`, href: `/audio-transcribe/new` })
  }

  return (
    <Authenticator
      hideSignUp={true}>
      {({ signOut, user }) => (
        <>


          <TopNavigation
            identity={{
              href: "/",
              title: "City Trax",
            }}
            utilities={[
              {
                type: "button",
                text: "Help",
                href: "mailto:support@citytrax.co.uk",
                external: true
              },
              {
                type: "menu-dropdown",
                text: email,
                description: email,
                iconName: "user-profile",
                onItemClick: signOut,
                items: [
                  { id: "signout", text: "Sign out" }
                ]
              }
            ]}
          />
          <AppLayout
            navigationOpen={navigationOpen}
            onNavigationChange={toggleNavigation}
            navigation={
              <SideNavigation
                activeHref={activeHref}
                header={{
                  href: '/',
                  logo: { alt: 'logo', src: "/NoveScript.png" },
                }}
                onFollow={event => {
                  if (!event.detail.external) {
                    event.preventDefault();
                    setActiveHref(event.detail.href);
                    navigate(event.detail.href)
                  }
                }}
                items={cityTraxItems}
              />
            }
            toolsHide={true}
            content={
              children ?? <Outlet />
            }
          />
        </>
      )
      }
    </Authenticator >


  );
}

export default App;
